import { render, staticRenderFns } from "./FloorSection.vue?vue&type=template&id=af177e7a&scoped=true&"
import script from "./FloorSection.vue?vue&type=script&setup=true&lang=ts&"
export * from "./FloorSection.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./FloorSection.vue?vue&type=style&index=0&id=af177e7a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af177e7a",
  null
  
)

export default component.exports
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItem from 'quasar/src/components/item/QItem.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QExpansionItem,QIcon,QItem});
