export type AVAILABLE_PLACEHOLDERS = 'BUILDING' | 'OFFICE'

export interface PlaceholderImage {
    blurHash: string,
    src: string
}

export const PLACEHOLDER_IMAGES: {
        [key in AVAILABLE_PLACEHOLDERS]: PlaceholderImage
    } = {
        BUILDING: {
            blurHash: 'LxIr${ayIToJ~qWVbbofRPj[xaWB',
            src: 'building.png'
        },
        OFFICE: {
            blurHash: 'LBLz{p]d00d:00T1%haf_400?GkW',
            src: 'placeholder.png'
        }
    }
;

