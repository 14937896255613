import { render, staticRenderFns } from "./BouncyRipple.vue?vue&type=template&id=90c20916&scoped=true&"
import script from "./BouncyRipple.vue?vue&type=script&lang=js&"
export * from "./BouncyRipple.vue?vue&type=script&lang=js&"
import style0 from "./BouncyRipple.vue?vue&type=style&index=0&id=90c20916&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90c20916",
  null
  
)

export default component.exports
import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'directives', {Ripple});
