import {Options} from "vue-class-component/dist/vue-class-component";

export type TableFilterType = 'tree' | 'checkbox';

export type TableId = string | number;

export interface TableFilterOption<T = boolean> {
    id: TableId;
    label: string;
    inline?: boolean;
    type?: TableFilterType;
    children?: TableFilterOption<T>[];
    value?: T;
}

export interface TableColumn {
    id: TableId;
    label: string;
}

export function getNumberOfAppliedFilters<T = boolean>(options: TableFilterOption<T>[]): number {
    let count = 0;
    options.forEach((option)=>{
        if (option.value) {
            count++;
        }
        for (const child of (option?.children ?? [])) {
            count += getNumberOfAppliedFilters([child]);
        }
    })
    return count;
}

export function getSelectedFilters<T = boolean>(option: TableFilterOption<T>): TableId[] {
    let result: TableId[] = [];

    if (option.children && option.children.length > 0) {
        result = [...result, ...option.children.flatMap(c => getSelectedFilters(c))]
    } else if (option.value) {
        result = [...result, option.id];
    }
    return result;
}