import { render, staticRenderFns } from "./RecipientsTable.vue?vue&type=template&id=23ff7452&scoped=true&"
import script from "./RecipientsTable.vue?vue&type=script&setup=true&lang=ts&"
export * from "./RecipientsTable.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./RecipientsTable.vue?vue&type=style&index=0&id=23ff7452&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23ff7452",
  null
  
)

export default component.exports
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTr,QTd});
