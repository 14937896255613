import {BASE_URL, partialAPIObject, WithRequiredProperties} from "@/services";
import axios, {AxiosResponse} from "axios";
import {APIServiceGroup} from "@/models/api/service-group.api";
import {
    APIButtonTemplateServiceMapping, APIButtonTemplateServiceMappingDetailed,
    APICreateService,
    APIService,
    APIUpdateService,
    CreatedServiceGroup
} from "@/models/api/service.api";
import {ServiceToCreate} from "@/models/service.model";
import {APIButtonTemplate} from "@/models/api/button-template.api";
import {APIRecipient} from "@/models/api/recipient.api";
import {Recipient} from "@/models/recipient.model";
import {
    ButtonTemplateDetailedResponse,
    ButtonTemplateMappedService,
    ButtonTemplateWithServices
} from "@/models/button-template.model";
import {APIDeviceType} from "@/models/api/device-type.api";
import {ApiButtonTemplateServiceMapping} from "@/models/api/button-template-service-mapping.api";
import {APIBuildingButtonTemplateData} from "@/models/api/buidling.api";

export class SettingsService {

    // CREATE
    static async createServiceGroup(serviceGroupName: string, services: ServiceToCreate[]): Promise<CreatedServiceGroup> {
        const createServiceGroupRequestBody = {
            name: serviceGroupName
        };
        const createdServiceGroup: APIServiceGroup = (await axios.post<APIServiceGroup>(`${BASE_URL}/v1/ServiceGroups/Create`, createServiceGroupRequestBody)).data;

        const createsServices: APIService[] = await Promise.all(services.map(async (service: ServiceToCreate): Promise<APIService> => (await SettingsService.createServices([{
            ...service,
            name: service.label
        }], createdServiceGroup.id))[0]));

        return {
            services: createsServices,
            serviceGroup: createdServiceGroup
        };
    }

    static async createServices(services: APIService[], serviceGroupId?: number | null): Promise<APIService[]> {

        const requests: Promise<APIService>[] = services.map(async (service): Promise<APIService> => {
            let createServiceRequestBody: APICreateService = {
                name: service.name,
                icon: service.icon,
            }

            if (serviceGroupId != undefined) {
                createServiceRequestBody = {
                    ...createServiceRequestBody,
                    serviceGroupId: serviceGroupId
                }
            }

            return (await axios.post<APIService>(`${BASE_URL}/v1/Services/Create`, createServiceRequestBody)).data
        });

        return Promise.all(requests);
    }


    static async createRecipient(recipient: Recipient): Promise<APIRecipient> {
        try {
            return (await axios.post<APIRecipient>(`${BASE_URL}/v1/Recipients/Create`, recipient)).data;
        } catch (e) {
            return Promise.reject(e);
        }
    }

    static async createButtonTemplate(templateName: string, services: ButtonTemplateMappedService[]): Promise<ButtonTemplateDetailedResponse> {
        const buttonTemplateData = {
            name: templateName
        };

        const templateData: APIButtonTemplate = (await axios.post<APIButtonTemplate>(`${BASE_URL}/v1/ButtonConfigTemplates/Create`, buttonTemplateData)).data;

        return {
            ...templateData,
            services: await this.createButtonTemplateServiceMapping(templateData.id, services)
        }
    }

    static async createButtonTemplateServiceMapping(buttonConfigTemplateId: number, services: ButtonTemplateMappedService[]): Promise<APIButtonTemplateServiceMapping[]> {

        const requests: Promise<APIButtonTemplateServiceMapping>[] = services.map(async (service: ButtonTemplateMappedService): Promise<APIButtonTemplateServiceMapping> => {
            const createServiceRequestBody: any = {
                serviceId: service.serviceId,
                buttonConfigTemplateId: buttonConfigTemplateId,
                buttonValue: Number(service.buttonValue),
                deviceTypeId: service.deviceTypeID
            }
            return (await axios.post<APIButtonTemplateServiceMapping>(`${BASE_URL}/v1/ButtonConfigTemplateServiceMappings/Create`, createServiceRequestBody)).data
        });

        return await Promise.all(requests);

    }

    // READ
    static async fetchServiceGroups(): Promise<APIServiceGroup[]> {
        const response: AxiosResponse<APIServiceGroup[]> = await axios.get(`${BASE_URL}/v1/ServiceGroups/Get`);
        return (await response).data;
    }

    static async fetchServices(): Promise<APIService[]> {
        const response: AxiosResponse<APIService[]> = await axios.get(`${BASE_URL}/v1/Services/Get`);
        return (await response).data;
    }

    static async fetchServiceById(serviceId: number): Promise<APIService> {
        const response: AxiosResponse<APIService> = await axios.get(`${BASE_URL}/v1/Services/GetById?id=${serviceId}`);
        return (await response).data;
    }

    static async fetchRecipients(): Promise<APIRecipient[]> {
        const response: AxiosResponse<APIRecipient[]> = await axios.get(`${BASE_URL}/v1/Recipients/Get`);
        return (await response).data || [];
    }

    static async fetchServicesByGroupId(serviceGroupId: number): Promise<APIService[]> {
        const response: AxiosResponse<APIService[]> = await axios.get(`${BASE_URL}/v1/Services/Get/ByServiceGroup?serviceGroupID=${serviceGroupId}`);
        return (await response).data || [];
    }

    static async fetchUnusedServices(): Promise<APIService[]> {
        const response: AxiosResponse<APIService[]> = await axios.get(`${BASE_URL}/v1/Services/Get/UnusedServices`);
        return response.data || [];
    }

    static async fetchServicesByButtonTemplate(buttonTemplateId: number): Promise<APIButtonTemplateServiceMapping[]> {
        const response: AxiosResponse<APIButtonTemplateServiceMapping[]> = await axios.get(`${BASE_URL}/v1/ButtonConfigTemplateServiceMappings/Get/ByButtonConfigTemplate?buttonConfigTemplateID=${buttonTemplateId}`);
        return (await response).data || [];
    }


    static async fetchButtonTemplates(): Promise<APIButtonTemplate[]> {
        const response: AxiosResponse<APIButtonTemplate[]> = await axios.get(`${BASE_URL}/v1/ButtonConfigTemplates/Get`);
        return (await response).data || [];
    }


    static async fetchButtonTemplateById(buttonTemplateId: number): Promise<APIButtonTemplate> {
        const response: AxiosResponse<APIButtonTemplate> = await axios.get(`${BASE_URL}/v1/ButtonConfigTemplates/GetByID?id=${buttonTemplateId}`);
        return (await response).data;
    }

    static async fetchButtonTemplatesWithServices(buttonTemplateId: number): Promise<ButtonTemplateWithServices> {
        const buttonTemplate = await this.fetchButtonTemplateById(buttonTemplateId);
        const buttonTemplateServices: APIButtonTemplateServiceMapping[] = await SettingsService.fetchServicesByButtonTemplate(buttonTemplateId);
        const apiServicePromise: Promise<APIService>[] = buttonTemplateServices.map(async (serviceMapping: APIButtonTemplateServiceMapping) => {
            return SettingsService.fetchServiceById(serviceMapping.serviceId);
        });
        const apiService: APIService[] = await Promise.all(apiServicePromise);
        return {
            buttonTemplate: buttonTemplate,
            services: buttonTemplateServices.map((s: APIButtonTemplateServiceMapping, idx) => {
                return {
                    ...s,
                    ...apiService[idx],
                    id: s.id,
                } as APIButtonTemplateServiceMappingDetailed
            })
        };
    }


    static async fetchDeviceTypes(): Promise<APIDeviceType[]> {
        const response: AxiosResponse<APIDeviceType[]> = await axios.get(`${BASE_URL}/v1/DeviceTypes/Get`);
        return (await response).data || [];
    }


    static async checkHasRelations(id: number, type: string): Promise<unknown> {
        const checkHasRelation: APIServiceGroup = (await axios.get<APIServiceGroup>(`${BASE_URL}/v1/Relations/ObtainRelations?id=${id}&type=${type}`)).data;
        return checkHasRelation;
    }

    // UPDATE
    static async updateServiceGroup(serviceGroupId: number, serviceGroupName: string, services: ServiceToCreate[], previousServices: APIService[]): Promise<void> {
        const createServiceGroupRequestBody = {
            id: serviceGroupId,
            name: serviceGroupName,
        };
        const createdServiceGroup: APIServiceGroup = (await axios.put<APIServiceGroup>(`${BASE_URL}/v1/ServiceGroups/Update`, createServiceGroupRequestBody)).data;

        const newServices: APIService[] = previousServices.filter(s => !s.id) ?? [];
        const toUpdateServices: APIService[] = previousServices.filter(s => s.id) ?? [];
        const servicesToDelete: APIService[] = previousServices.filter((previousService) => {
            return (services.filter(s => s.id == previousService.id) || []).length > 0;
        });

        return;
        const createdServices: Promise<APIService[]> = this.createServices(newServices, serviceGroupId);
        const updatedServices: Promise<APIService[]> = this.updateServices(toUpdateServices, serviceGroupId);
        const deletedServices: Promise<APIService[]> = this.deleteServices(servicesToDelete.map(s => s.id).filter(v => v) as number[]);

        await Promise.all([createdServices, updatedServices, deletedServices]);

        return;

    }

    static updateServiceServiceGroup(serviceId: number, serviceGroupId: number): Promise<APIService> {
        return this.updateServices([{id: serviceId}], serviceGroupId).then((res) => res[0]);
    }

    static async updateServices(services: (Partial<APIService>)[], serviceGroupId?: number | null): Promise<APIService[]> {

        const requests: Promise<APIService | null>[] = services.map(async (service): Promise<APIService | null> => {

            if (!service.id) {
                return null;
            }
            let updateServiceRequestBody: APIUpdateService = {
                id: service.id,
                ...(service.name ? {name: service.name} : {}),
                ...(service.icon ? {icon: service.icon} : {}),
            };

            if (serviceGroupId != undefined) {
                updateServiceRequestBody = {
                    ...updateServiceRequestBody,
                    serviceGroupId: serviceGroupId
                }
            }

            return (await axios.put<APIService>(`${BASE_URL}/v1/Services/Update`, updateServiceRequestBody)).data
        });

        return Promise.all(requests).then(values => values.filter(Boolean) as APIService[]);

    }


    static async updateRecipient(recipient: Partial<Recipient>, recipientId: number): Promise<APIRecipient> {

        const recipientBody = {
            ...recipient,
            id: recipientId
        }
        return (await axios.put<APIRecipient>(`${BASE_URL}/v1/Recipients/Update`, recipientBody)).data

    }


    static async updateButtonConfigTemplate(templateName: string, templateId: number): Promise<APIButtonTemplate> {

        const buttonTemplateData = {
            name: templateName,
            id: templateId
        };

        return (await axios.put<APIButtonTemplate>(`${BASE_URL}/v1/ButtonConfigTemplates/Update`, buttonTemplateData)).data;

    }


    static async updateServiceMapping(serviceMappingId: number, mapping: Partial<ApiButtonTemplateServiceMapping>): Promise<ApiButtonTemplateServiceMapping> {

        const buttonMappingData: Partial<ApiButtonTemplateServiceMapping> = {
            ...mapping,
            id: serviceMappingId
        };

        return (await axios.put<ApiButtonTemplateServiceMapping>(`${BASE_URL}/v1/ButtonConfigTemplateServiceMappings/Update`, buttonMappingData)).data;
    }

    // DELETE
    static async deleteServices(serviceIds: number[]): Promise<APIService[]> {
        const requests: Promise<APIService>[] = serviceIds.map(async (serviceId: number): Promise<APIService> => {
            return (await axios.delete<APIService>(`${BASE_URL}/v1/Services/Delete?id=${serviceId}`)).data;
        });

        return Promise.all(requests);
    }

    static async deleteRecipient(recipientId: number): Promise<void> {
        return (await axios.delete<void>(`${BASE_URL}/v1/Recipients/Delete?id=${recipientId}`)).data;
    }

    static async deleteServiceGroup(serviceGroupId: number): Promise<void> {
        return (await axios.delete<void>(`${BASE_URL}/v1/ServiceGroups/Delete?id=${serviceGroupId}`)).data;
    }

    static deleteServiceServiceGroup(serviceId: number, serviceGroupId: number): Promise<APIService> {
        return this.updateServices([{id: serviceId}], null).then((res) => res[0]);
    }

    static async deleteButtonTemplateService(mappingId: number): Promise<void> {
        return (await axios.delete<void>(`${BASE_URL}/v1/ButtonConfigTemplateServiceMappings/Delete?id=${mappingId}`)).data;
    }

    static async deleteButtonTemplate(templateId: number): Promise<void> {
        return (await axios.delete<void>(`${BASE_URL}/v1/ButtonConfigTemplates/Delete?id=${templateId}`)).data;
    }
}