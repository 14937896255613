import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/default/HomeView.vue"
import Impressum from "../views/default/ImpressumView.vue"
import PageNotFound from "../views/default/PageNotFound.vue"

/* ServiceButton */
import ResetPage from "../views/Reset/ResetPage.vue"
import store from '../store';
import StatusPage from "../views/StatusPage.vue";
import QRPage from "../views/QRPage.vue";
import SettingsPage from "@/views/SettingsPage.vue";
import BuildingConfigurationPage from "@/views/BuildingConfigurationPage.vue";
import {KeycloakLoginOptions} from "keycloak-js";

Vue.use(VueRouter)

const routes = [{
    path: "/",
    name: "Home",
    component: Home
},
    {
        path: "/impressum",
        name: "Impressum",
        component: Impressum
    },
    {
        path: "*",
        name: "Page Not Found",
        component: PageNotFound
    },
    {
        path: "/reset",
        name: "Reset",
        component: ResetPage,
    },
    {
        path: "/qr",
        name: "Service Button",
        component: QRPage,
    },
    {
        path: '/services',
        name: 'Services',
        component: StatusPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/building-configuration',
        name: 'Building Configuration',
        component: BuildingConfigurationPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/settings',
        name: 'Settings',
        component: SettingsPage,
        meta: {
            requiresAuth: true
        }
    },

]

const router: VueRouter = new VueRouter({
    routes,
    mode: "history"
})


router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters["keycloak/getProfile"]) {
            next();
            return;
        }
        await store.dispatch('keycloak/tryLogin', {redirectUri: `${window.location.origin}${to.fullPath}`} as KeycloakLoginOptions);
    } else {
        next()
    }
})

router.afterEach(() => {
    document.querySelector("html")?.scrollTo(0, 0);
});

export default router