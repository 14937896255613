
import {TableColumn, TableFilterOption} from "@/models/table.model";
import TableFilter from "@/components/status-table/TableFilter.vue";
import {Component, Vue} from "vue-property-decorator";
import {EventBus, TableService, WS_EVENTS} from "@/services";
import {Getter} from "@/store/store.utils";
import ServiceConfirmation from "@/components/dialogs/ServiceConfirmationDialog.vue";
import TableDataRender from "@/components/status-table/TableDataRender.vue";
// import UpdateDataDialog from "@/components/dialogs/UpdateDataDialog.vue";

@Component({
    components: {TableDataRender, TableFilter},
})
export default class StatusPage extends Vue {

    // public filterOptions: TableFilterOption[] = []
    @Getter('filters/getFilters') public filters?: TableFilterOption[];
    @Getter('lang/getText') public translate!: any;
    public columns: unknown[] = [];
    public data: unknown[] = [];
    public loadingData: boolean = false;
    public loadingFilters: boolean = false;
    public loadingReset: {
        [key: number]: boolean
    } = {};
    public pagination = {
        page: 1,
        rowsPerPage: 5,
        rowsNumber: 0
    };

    mounted(): void {
        this.loadingFilters = true;
        this.loadingData = true;
        this.$store.dispatch('filters/getColumns').then((res: TableColumn[]) => {
            this.columns = res.map((column) => {
                return {
                    name: column.label, label: this.translate(column.label), field: column.id, align: 'left'
                }
            });
            this.getTableData();
        });
        EventBus.$on(WS_EVENTS.STATUS_CHANGE, this.statusChangeHandler);
    }

    beforeDestroy(): void {
        EventBus.$off(WS_EVENTS.STATUS_CHANGE, this.statusChangeHandler);
    }


    public statusChangeHandler() {
        this.getTableData();
        // if()
        // this.$q.dialog({
        //     component: UpdateDataDialog
        // });
    }

    public onClearFilters() {
        this.loadingFilters = true;
        this.$store.dispatch('filters/clearFilterValues');
        this.getTableData().then(() => {
            this.$store.commit('ui/setFilterMenuOpened', false);
        });
    }

    public onSaveFilters(event: TableFilterOption[]) {
        this.loadingFilters = true;
        this.$store.commit('filters/setFilters', {filters: event});
        this.getTableData();
    }

    public onRequest(props: { pagination: any }) {
        this.pagination = {
            ...this.pagination,
            ...props.pagination
        };
        this.getTableData();
    }

    public resetService(guid: string, rowIndex: number) {
        this.$q.dialog({
            component: ServiceConfirmation
        }).onOk(() => {
            this.loadingReset = {
                ...this.loadingReset,
                [rowIndex]: true
            }
            TableService.resetService(guid).then(() => {
                this.getTableData();
            }).finally(() => {
                delete this.loadingReset[rowIndex];
            });
        });
    }

    public getPaginationLabel(firstRowIndex: number, endRowIndex: number, totalRows: number) {
        return `${firstRowIndex}-${endRowIndex} ${this.translate('of')} ${totalRows}`;
    }

    get getRecordsPerPageLabel() {
        return `${this.translate('records-per-page')}`;
    }

    get getLoadingLabel() {
        return `${this.translate('table-loading')}`;
    }

    get statusColumn() {
        return this.columns.filter(col => (col as { field: string }).field == 'Status')[0];
    }

    private getTableData(): Promise<void> {
        this.loadingData = true;
        return TableService.fetchTableData({
            PageNumber: this.pagination.page,
            NumberOfRecordsPerPage: this.pagination.rowsPerPage
        }).then((result) => {
            this.data = result.Data;

            this.pagination.page = result.Pagination.PageNumber;
            this.pagination.rowsPerPage = result.Pagination.NumberOfRecordsPerPage;
            this.pagination.rowsNumber = result.Pagination.TotalRecords;

            this.loadingData = false;
            this.loadingFilters = false;
        });
    }

}
