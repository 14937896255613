export type PartialWithValues<T> = Pick<
    T,
    { [Key in keyof T]-?: T[Key] extends null | undefined ? never : Key }[keyof T]
>;

export type WithRequiredProperties<Type, Key extends keyof Type> = Partial<Type> & {
    [Property in Key]-?: Type[Property];
};


export const partialAPIObject = (obj: {
    [key: string]: any
}) => {
    const returnObj: { [key: string]: any } = {};
    const valuableKeys: string[] = Object.keys(obj).filter(k => {
        return obj[k] != null || obj[k] != undefined;
    });

    for (const el of valuableKeys) {
        returnObj[el] = obj[el];
    }

    return returnObj;
}