import { render, staticRenderFns } from "./SideBar.vue?vue&type=template&id=9246c7d8&scoped=true&"
import script from "./SideBar.vue?vue&type=script&setup=true&lang=ts&"
export * from "./SideBar.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./SideBar.vue?vue&type=style&index=0&id=9246c7d8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9246c7d8",
  null
  
)

export default component.exports
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QList,QItem,QItemSection,QIcon,QSeparator,QSelect,QAvatar,QDrawer});qInstall(component, 'directives', {Ripple});
