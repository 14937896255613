import { render, staticRenderFns } from "./SettingsPage.vue?vue&type=template&id=44e6017c&scoped=true&"
import script from "./SettingsPage.vue?vue&type=script&setup=true&lang=ts&"
export * from "./SettingsPage.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./SettingsPage.vue?vue&type=style&index=0&id=44e6017c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44e6017c",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn});
