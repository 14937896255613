import Vue from 'vue'

import './styles/quasar.scss'
import '@quasar/extras/animate/fadeIn.css'
import '@quasar/extras/animate/fadeOut.css'

import iconSet from 'quasar/icon-set/mdi-v4.js'
import '@quasar/extras/mdi-v4/mdi-v4.css'
import '@quasar/extras/material-symbols-outlined/material-symbols-outlined.css'
import '@quasar/extras/material-symbols-rounded/material-symbols-rounded.css'
import Dark from 'quasar/src/plugins/Dark.js';import Dialog from 'quasar/src/plugins/Dialog.js';import LoadingBar from 'quasar/src/plugins/LoadingBar.js';import Notify from 'quasar/src/plugins/Notify.js';import Quasar from 'quasar/src/vue-plugin.js';;
import VueBlurHash from 'vue-blurhash';

Vue.use(VueBlurHash);


Vue.use(Quasar, {
    config: {
        loadingBar: {
            color: 'blue-grey-10'
        },
        dark: false,
        notify: {}
    },
    plugins: {
        LoadingBar,
        Dialog,
        Notify,
        Dark
    },
    iconSet: iconSet
})