import { render, staticRenderFns } from "./NewButtonTemplateDialog.vue?vue&type=template&id=15d8ef78&scoped=true&"
import script from "./NewButtonTemplateDialog.vue?vue&type=script&setup=true&lang=ts&"
export * from "./NewButtonTemplateDialog.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./NewButtonTemplateDialog.vue?vue&type=style&index=0&id=15d8ef78&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15d8ef78",
  null
  
)

export default component.exports
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSeparator});
