<template>
    <QLayout view="lHh LpR lff">
        <q-header v-if="showHeader" :class="{'primary': (routeName?.toUpperCase() === '/'), 'transparent no-border': routeName?.toUpperCase() === '/QR'}" :bordered="getHeaderBorder">
            <q-toolbar>
                <q-btn v-if="!persistent" flat @click="drawer = !drawer" round dense icon="sym_o_menu"/>
                <q-toolbar-title>{{ pageName }}</q-toolbar-title>
                <portal-target name="header-right"></portal-target>
            </q-toolbar>
        </q-header>
        <ISSSideBar v-if="showSideBar" v-model="drawer" :items="SIDE_BAR_ITEMS" @persistent="persistent = $event"></ISSSideBar>
        <q-page-container>
            <q-page>
                <keep-alive include="ServicesPage">
                    <router-view/>
                </keep-alive>
            </q-page>
            <PopupWindow v-if="loader" :canBeToggled="false" :coverWindow="true" :clearBackground="true"
                         :loader="true"></PopupWindow>
        </q-page-container>

        <q-footer v-if="showHeader">
            <q-toolbar>
                <DexFooter>
                    <li>
                        <router-link to="/impressum">{{ getText("imprint") }}</router-link>
                    </li>
                    <li>
                        <a href="https://www.de.issworld.com/de-de/datenschutz"
                           target="_blank">{{ getText("privacy") }}</a>
                    </li>
                    <li>
                        <a href="https://www.de.issworld.com/de-de/karriere/ihre-karriere-bei-iss/karriere"
                           target="_blank">{{ getText("careers") }}</a>
                    </li>
                    <li>
                        <a href="https://www.de.issworld.com/de-de/ueber-iss/people-make-places/ueber-iss"
                           target="_blank">{{ getText("about-iss") }}</a>
                    </li>
                    <li>
                        <a href="https://www.de.issworld.com/de-de/ueber-iss/kontakt/kontakt-zu-iss"
                           target="_blank">{{ getText("contact") }}</a>
                    </li>
                </DexFooter>
            </q-toolbar>
        </q-footer>
    </QLayout>
</template>

<script>
import DexFooter from "@/components/default/DexFooter.vue"
import PopupWindow from "@/components/default/PopupWindow.vue"
import {mapGetters, mapActions, mapMutations} from "vuex"
import * as dexlib from "@/assets/js/dexlib.js"
import {StatusChange} from "@/services";
import ISSSideBar from "@/components/SideBar.vue";
import {QFooter, QLayout, QPageContainer} from "quasar";
import {SIDE_BAR_ITEMS} from "@/configs/sidebar.config";
import {PortalTarget} from "portal-vue";

export default {
    name: "app",
    components: {
        PortalTarget,
        QPageContainer,
        QLayout,
        ISSSideBar,
        DexFooter,
        PopupWindow,
        QFooter
    },
    data() {
        return {
            appName: "Service Button",
            SIDE_BAR_ITEMS: SIDE_BAR_ITEMS,
            drawer: false,
            persistent: true,
            routeName: null,
            showSideBar: true,
            showHeader: true
        }
    },
    computed: {
        ...mapGetters("lang", {
            getText: "getText",
            getLang: "getActiveLang",
        }),
        ...mapGetters("loader", {
            loader: "getLoader"
        }),
        ...mapGetters("keycloak", {
            getKeycloak: "getKeycloak",
            getProfile: "getProfile",
            getInitials: "getInitials",
            hasClientRoles: "hasClientRoles",
            getCheckSso: "getSsoCheck",
        }),
        ...mapGetters('ui', {
            getHeaderBorder:"getHeaderBorder"
        }),
        pageName() {
            return this.$route.name;
        }
    },
    watch: {
        $route() {
            this.setupTitles();
        }
    },
    methods: {
        ...mapMutations("lang", {
            setLanguage: "setActiveLang",
        }),
        ...mapActions("keycloak", {
            // tryLogin: "tryLogin",
            tryLogout: "tryLogout",
            initKeycloak: "initKeycloak"
        }),
        contentMinHeightHandler: function () {
            if (!document.querySelector("#app>div")) return;
            // document.querySelector("#app>div").style.setProperty(
            //     "min-height",
            //     `calc(100vh - ${window.getComputedStyle(document.querySelector("header")).height
            //     } - ${window.getComputedStyle(document.querySelector("footer")).height
            //     })`
            // );
            document.querySelector("#app>div").style.setProperty(
                "height",
                `calc(100vh - ${window.getComputedStyle(document.querySelector("header")).height
                })`
            );
        },
        tryLogin() {
            this.$store.dispatch('keycloak/tryLogin');
        },
        setupTitles(){
            this.routeName = this.$route.path;
            document.querySelector("title").innerHTML = this.$route.name.toLowerCase() === 'home' ? this.appName : `${this.$route.name} - ${this.appName}`;


            const isQR = this.routeName.toUpperCase() === '/QR';
            this.showSideBar = !isQR;
        }
    },
    async mounted() {
        if (dexlib.getParam("lang")) {
            this.setLanguage({lang: dexlib.getParam("lang")});
        } else {
            this.setLanguage({lang: navigator.language.slice(0, 2)});
        }
        this.contentMinHeightHandler();
        window.addEventListener("resize", () => {
            this.contentMinHeightHandler();
        });
        document.querySelector("title").innerHTML = this.appName;
        StatusChange.start();
        this.setupTitles();
    },
    updated() {
        this.contentMinHeightHandler();
    },
}
</script>

<style lang="scss">
</style>
