
import {Vue, Component, Prop, Ref} from 'vue-property-decorator';
import {Getter} from "@/store/store.utils";
import QDialog from 'quasar/src/components/dialog/QDialog.js';import QBanner from 'quasar/src/components/banner/QBanner.js';;

@Component({})
export default class ErrorDialog extends Vue {

    @Prop() title?: string;
    @Prop() description?: string;
    @Prop() messages?: string[]

    @Getter('lang/getText') public translate!: any;
    @Ref() readonly dialog!: QDialog;


    public show() {
        this.dialog.show();
    }

    public hide() {
        this.dialog.hide();
    }

    public onDialogHide() {
        this.$emit('hide');
    }

    get hasMessages() {
        return (this.messages?.length ?? 0) > 0;
    }

}
