import { render, staticRenderFns } from "./ButtonCard.vue?vue&type=template&id=8388a552&scoped=true&"
import script from "./ButtonCard.vue?vue&type=script&lang=ts&"
export * from "./ButtonCard.vue?vue&type=script&lang=ts&"
import style0 from "./ButtonCard.vue?vue&type=style&index=0&id=8388a552&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8388a552",
  null
  
)

export default component.exports
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QSpinnerIos from 'quasar/src/components/spinner/QSpinnerIos.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSkeleton,QSpinnerIos,QIcon});
