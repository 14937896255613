import {createDecorator} from "vue-class-component";
import Store from './index';
import {mapGetters} from "vuex";

export function Getter(getterType: string) {
    return createDecorator((options, key) => {
        if (!options.computed) options.computed = {}
        options.computed[key] = function () {
            return Store.getters[getterType]
        }
    })
}

export function MapGetters(namespace: string, states: string[]) {
    return createDecorator(options => {
        if (!options.computed) {
            options.computed = {}
        }
        Object.assign(options.computed, mapGetters(namespace, states))
    })
}