import axios from "axios";
import Keycloak, {KeycloakInitOptions, KeycloakInstance, KeycloakLoginOptions, KeycloakProfile} from "keycloak-js";
import Vue from "vue";
import {ActionContext, ActionTree} from "vuex";


export interface AuthInitOptions extends KeycloakInitOptions {
    url: string;
    realm: string;
    clientId: string;
}

export interface KeycloakState {
    status: boolean;
    keycloak: KeycloakInstance;
    profile: any;
    tokenParsed: any;
    ready: boolean;
}


export default {
    namespaced: true,
    state: {
        status: false,
        keycloak: undefined,
        profile: undefined,
        tokenParsed: undefined,
        ready: false
    } as Partial<KeycloakState>,
    getters: {
        getStatus: function (state: KeycloakState) {
            return state.status;
        },
        getKeycloak: function (state: KeycloakState) {
            return state.keycloak;
        },
        getProfile: function (state: KeycloakState) {
            return state.profile;
        },
        getToken: function (state: KeycloakState) {
            return state.tokenParsed;
        },
        getReady: function (state: KeycloakState) {
            return state.ready;
        },
        getInitials: function (state: KeycloakState) {
            let i: string = "";
            if (!state.profile) return i;
            if (state.profile.firstName) i += state.profile.firstName.slice(0, 1);
            if (state.profile.lastName) i += state.profile.lastName.slice(0, 1);
            return i;
        },
        hasClientRoles: (state: KeycloakState) => (roleArray: string[]) => {
            if (
                !state.tokenParsed ||
                !state.profile ||
                Object.keys(state.tokenParsed).indexOf("client_roles") < 0
            )
                return false;
            const roles: string[] = state.tokenParsed["client_roles"];
            const decapitalize = (role: string) => {
                return role.toLowerCase();
            };
            for (let i = 0; i < roleArray.length; i++) {
                if (roles.map(decapitalize).indexOf(roleArray[i]) < 0) {
                    return false;
                }
            }
            return true;
        },
    },
    mutations: {
        setKeycloakConnection: function (state: KeycloakState, payload: KeycloakState) {
            if (payload.keycloak == undefined)
                throw "Payload is missing property, 'keycloak'";
            Vue.set(state, "keycloak", payload.keycloak);
        },
        setKeycloakProfile: function (state: KeycloakState, payload: KeycloakState) {
            if (payload.profile == undefined)
                throw "Payload is missing property, 'profile'";
            Vue.set(state, "profile", payload.profile);
        },
        setTokenParsed: function (state: KeycloakState, payload: KeycloakState) {
            if (payload.tokenParsed == undefined)
                throw "Payload is missing property, 'tokenParsed'";
            Vue.set(state, "tokenParsed", payload.tokenParsed);
        },
        setStatus: function (state: KeycloakState, payload: KeycloakState) {
            if (payload.status == undefined)
                throw "Payload is missing property, 'status'";
            Vue.set(state, "status", payload.status);
        },
        setReady: function (state: KeycloakState, payload: KeycloakState) {
            if (payload.ready == undefined)
                throw "Payload is missing property, 'ready'";
            Vue.set(state, "ready", payload.ready);
        },
    },
    actions: {
        async tryLogin(context: ActionContext<KeycloakState, KeycloakState>, payload?: KeycloakLoginOptions) {
            return context.state.keycloak.login(payload);
        },
        async tryLogout(context: ActionContext<KeycloakState, KeycloakState>) {
            localStorage.removeItem("kc_token");
            localStorage.removeItem("kc_refresh_token");
            await context.state.keycloak.logout({redirectUri: `${encodeURI(window.location.origin)}`}).then((res) => {
                context.commit("setReady", {ready: false});
            });
            return null;
        },
        async initKeycloak(context: ActionContext<KeycloakState, KeycloakState>): Promise<void> {
            return new Promise((resolve) => {
                return axios
                    .get("/api/v1/AuthCredentials/credentials")
                    .then((res) => {
                        const initOptions: AuthInitOptions = {
                            url: res.data.data.url + "/auth",
                            realm: res.data.data.realm,
                            clientId: res.data.data.clientId,
                            checkLoginIframe: false,
                            onLoad: "check-sso",
                        };
                        const kc: Keycloak = new Keycloak(initOptions);
                        kc.onReady = (authenticated) => {
                            context.commit("setReady", {ready: authenticated});
                        }
                        context.commit("setKeycloakConnection", {keycloak: kc});
                        return kc
                            .init({
                                onLoad: initOptions.onLoad,
                                checkLoginIframe: initOptions.checkLoginIframe,
                                token: localStorage.getItem("kc_token") as string,
                                refreshToken: localStorage.getItem("kc_refresh_token") as string,
                            })
                            .then((isAuthenticated) => {
                                if (isAuthenticated) {
                                    return context.state.keycloak
                                        .loadUserProfile()
                                        .then((profile: KeycloakProfile) => {
                                            if (!context.state.keycloak.token || !context.state.keycloak.refreshToken) {
                                                return;
                                            }
                                            localStorage.setItem(
                                                "kc_token",
                                                context.state.keycloak.token
                                            );
                                            localStorage.setItem(
                                                "kc_refresh_token",
                                                context.state.keycloak.refreshToken
                                            );
                                            context.commit("setKeycloakProfile", {
                                                profile: profile,
                                            });
                                            context.commit("setTokenParsed", {
                                                tokenParsed: context.state.keycloak.tokenParsed,
                                            });
                                            setInterval(() => {
                                                if (context.state.keycloak.isTokenExpired()) {
                                                    context.state.keycloak
                                                        .updateToken(6)
                                                        .then((status: boolean) => {
                                                            if (status) {
                                                                if (!context.state.keycloak.token || !context.state.keycloak.refreshToken) {
                                                                    return;
                                                                }
                                                                localStorage.setItem(
                                                                    "kc_token",
                                                                    context.state.keycloak.token
                                                                );
                                                                localStorage.setItem(
                                                                    "kc_refresh_token",
                                                                    context.state.keycloak.refreshToken
                                                                );
                                                                context.commit("setTokenParsed", {
                                                                    tokenParsed:
                                                                    context.state.keycloak.tokenParsed,
                                                                });
                                                            }
                                                        })
                                                        .catch((e: any) => {
                                                            console.warn(
                                                                "Failed to update token:",
                                                                e.message
                                                            );
                                                        });
                                                }
                                            }, 5 * 1000);
                                        });
                                }
                            });
                    })
                    .finally(() => {
                        context.commit("setStatus", {status: true});
                        resolve();
                    });
            });
        },
    } as ActionTree<KeycloakState, KeycloakState>,
};
