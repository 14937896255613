import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import "./assets/css/dex.css"
import "./assets/css/dexcon.css"
import axios from "axios"
import './quasar'
import "./styles/iot.style.scss"
import 'vue-blurhash/dist/vue-blurhash.css'
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import VueMoment from 'vue-moment';
import {createI18n} from 'vue-i18n-bridge'
import VueI18n from "vue-i18n";
import PortalVue from 'portal-vue';

Vue.config.productionTip = false;
Vue.use(VueI18n, {bridge: true});
Vue.use(VueMoment);
Vue.use(PortalVue);

export let SBApp: Vue;
export const i18n = createI18n({
    legacy: false
}, VueI18n);

Vue.use(i18n);

const setupKeycloak = store.dispatch('keycloak/initKeycloak');
const setupLanguage = async () => {
    const enMessages = axios.get('/languages/en.json').then((res) => res.data);
    const deMessages = axios.get('/languages/de.json').then((res) => res.data);
    const ptMessages = axios.get('/languages/pt.json').then((res) => res.data);
    const dkMessages = axios.get('/languages/dk.json').then((res) => res.data);
    const res = await Promise.all([enMessages, deMessages, ptMessages, dkMessages]);
    i18n.global.setLocaleMessage('en', res[0]);
    i18n.global.setLocaleMessage('de', res[1]);
    i18n.global.setLocaleMessage('pt', res[2]);
    i18n.global.setLocaleMessage('dk', res[3]);
}
Promise.all([setupKeycloak, setupLanguage()]).then(() => {
    SBApp = new Vue({
        router,
        store,
        render: function (h) {
            return h(App)
        }
    }).$mount("#app");
});


axios.interceptors.request.use(req => {
    req.headers.Authorization = `Bearer ${localStorage.getItem("kc_token")}`;
    return req;
});

axios.interceptors.response.use(res => {
    return res;
}, (error) => {
    const dialog = SBApp.$q.dialog({
        component: ErrorDialog,
        messages: error?.response?.data?.errors?.Messages
    });

    setTimeout(() => {
        dialog.hide();
    }, error?.response?.data?.errors?.Messages ? 8000: 5000);

    return Promise.reject(error);
});


