/* NOTE:
 * - This file is only relevant when you would like to use dynamic text retrieval, i.e. text are
 *   are not hardcoded into the code
 * - Look for TODOs
 */

import * as dexlib from "../../assets/js/dexlib.js"
import axios from "axios"
import {i18n} from "@/main";

export default ({
    namespaced: true,
    state: {
        activeLang: window.localStorage.lang ? window.localStorage.lang : "de", //TODO: Set the default language for your app
        /* NO NEED TO MODIFY VARIABLES BELOW */
        dictionary: null,
        isRetrievingTextFile: false
    },
    getters: {
        getText: (state) => (getters) => {
            if (!state.dictionary) {
                if (state.isRetrievingTextFile) {
                    // An axios request is underway, we prevent further axios requests
                    return;
                }
                state.isRetrievingTextFile = true;
                axios.get(`/languages/${state.activeLang}.json`) // TODO: Adjust text file URL
                    .then((res) => {
                        state.dictionary = res.data;
                        state.isRetrievingTextFile = false;
                    })
                    .catch((e) => {
                        dexlib.vuewarn(`Failed to retrieve file - ${e}`);
                        state.isRetrievingTextFile = false;
                    });
            } else {
                state.isRetrievingTextFile = false;
                if (state.dictionary[getters] != undefined) {
                    return state.dictionary[getters]
                } else {
                    dexlib.vuewarn(`Missing dictionary entry for '${state.activeLang}/${getters}'`);
                    return getters;
                }
            }
        },
        getActiveLang: function (state) {
            return state.activeLang;
        }
    },
    mutations: {
        setActiveLang: async function (state, payload) {
            if (payload.lang == undefined) return;
            i18n.global.locale.value = payload.lang;
            axios.get(`/languages/${payload.lang}.json`)
                .then((res) => {
                    state.activeLang = payload.lang;
                    state.dictionary = res.data;
                    dexlib.vuelog(`Active language set to '${state.activeLang}'`);
                    dexlib.setParam("lang", payload.lang);
                    document.querySelector("html").setAttribute("lang", payload.lang);
                })
                .catch((e) => {
                    dexlib.vuewarn(`Language '${payload.lang}' was not found in the list of supported languages - ${e}`);
                });
        }
    }
})