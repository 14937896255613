import {APIBuilding, APIBuildingMetadata} from "@/models/api/buidling.api";
import {ActionContext} from "vuex";
import Vue from "vue";
import {BuildingService} from "@/services";

const SELECTED_BUILDING_LOCAL_STORAGE_KEY: string = 'SELECTED_BUILDING';

export interface BuildingState {
    selectedBuilding: APIBuilding | undefined;
}


export default {
    namespaced: true,
    state: {
        selectedBuilding: undefined,
    } as BuildingState,
    getters: {
        getSelectedBuilding(state: BuildingState): APIBuilding | undefined {
            return state.selectedBuilding;
        },
    },
    mutations: {
        setSelectedBuilding(state: BuildingState, payload: BuildingState) {
            if (!payload.selectedBuilding) {
                return;
            }
            localStorage.setItem(SELECTED_BUILDING_LOCAL_STORAGE_KEY, JSON.stringify(payload.selectedBuilding));
            Vue.set(state, "selectedBuilding", payload.selectedBuilding);
        }
    },
    actions: {
        async getSelectedBuilding(context: ActionContext<BuildingState, BuildingState>) {
            let selectedBuilding: APIBuilding | undefined = context.state.selectedBuilding;
            let metadata: APIBuildingMetadata;
            if (!selectedBuilding) {
                const selected: string | null = localStorage.getItem(SELECTED_BUILDING_LOCAL_STORAGE_KEY);
                if (selected) {
                    selectedBuilding = JSON.parse(selected);
                    await context.commit("setSelectedBuilding", {selectedBuilding: selectedBuilding});
                }
            }

            if(!selectedBuilding){
                return null;
            }else{
                metadata = (await BuildingService.fetchBuildingMetadata([selectedBuilding.id]))[0];
                selectedBuilding.metadata = metadata;
                await context.commit("setSelectedBuilding", {selectedBuilding: selectedBuilding});
            }

            return context.state.selectedBuilding;
        }
    }
}