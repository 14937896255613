
import {Component, Prop, Vue} from "vue-property-decorator";
import QBtn from 'quasar/src/components/btn/QBtn.js';import QList from 'quasar/src/components/item/QList.js';import QMenu from 'quasar/src/components/menu/QMenu.js';;
import {TableFilterOption} from "@/models/table.model";
import {Getter} from "@/store/store.utils";

@Component({
    components: {
        QList, QMenu,
        QBtn,
    }
})
export default class TableFilterMenuHeader extends Vue {
    @Prop()
    public parent?: TableFilterOption;

    @Prop()
    public nAppliedFilters?: number

    @Prop()
    public expanded?: boolean;

    @Getter('lang/getText') public translate!: any;
}
