

import {Component, Vue} from "vue-property-decorator";
import ButtonCard from "@/components/cards/ButtonCard.vue";
import QCard from 'quasar/src/components/card/QCard.js';;
import {EventBus, QRCodeService, WS_EVENTS} from "@/services";
import {APIDeviceData} from "@/models/api/device.api";
import {DeviceData, ServiceData, ServiceStatus} from "@/models/device.model";
import {APIServiceStatus} from "@/models/api/status.api";
import ISSBlurredHash from "@/components/ISSBlurredHash.vue";


const PLACEHOLDER_DATA = {
    blurHash: 'LBLqX^]d00d:00T1%haf_400?Go#',
    image: '/placeholder.jpeg'
}

@Component({
    components: {ISSBlurredHash, ButtonCard, QCard},
})
export default class QRPage extends Vue {
    public atTop: boolean = false;
    public stacked: boolean = false;
    public placeholderData = PLACEHOLDER_DATA;
    public loadingData: boolean = false;
    public loadingStatus: boolean = false;
    public deviceData: DeviceData | null = null;
    public activeServices: ServiceStatus[] = [];
    public deviceGUID?: string;
    public loadingServices: { [key: string]: boolean } = {};

    // Vue lifecycle
    mounted() {
        EventBus.$on(WS_EVENTS.STATUS_CHANGE, this.statusChangeHandler);
        this.deviceGUID = `${this.$route.query.guid}`;
        this.getButtonService();
    }

    beforeDestroy() {
        EventBus.$off(WS_EVENTS.STATUS_CHANGE, this.statusChangeHandler);
    }

    // Methods
    public onScroll(event: any) {
        const element: HTMLElement = (this.$refs.lastElem as HTMLElement[])?.[0];
        if (!element) {
            return;
        }
        const lastElementDistanceTop = element.getBoundingClientRect().y - event.target.offsetTop;
        this.stacked = lastElementDistanceTop <= 180
        this.atTop = lastElementDistanceTop <= 100;
    }

    public stackScroll(event: any) {
        const element: HTMLElement = (this.$refs.lastElem as HTMLElement[])[0];
        const lastElementDistanceTop = element.getBoundingClientRect().y - event.target.offsetTop;
        this.stacked = lastElementDistanceTop <= 20;
        this.atTop = lastElementDistanceTop <= 100;
    }

    public listItemStyle(i: number, backgroundRGB?: string) {
        const style: any = {};
        style['--top-offset'] = `${i * 10}px`;

        if (backgroundRGB) {
            style.backgroundColor = `rgba(${backgroundRGB}, 1)`;
        }
        return style;
    }

    public refresh(done: () => void) {
        setTimeout(() => {
            done();
        }, 5000);
    }

    public statusChangeHandler() {
        this.getButtonStatus();
    }

    public getButtonService() {
        if (!this.deviceGUID) {
            //handle invalid GUID
        }
        this.loadingData = true;
        QRCodeService.fetchButtonServices(this.deviceGUID!).then(async (data: APIDeviceData) => {
            this.deviceData = data;
            this.placeholderData.image = this.deviceData?.Building?.Image?.ImageData || this.placeholderData.image;
            this.placeholderData.blurHash = this.deviceData?.Building?.Image?.ImageHashData || this.placeholderData.blurHash;
            this.loadingData = false;
            this.getButtonStatus();
        });
    }

    public getButtonStatus() {
        if (!this.deviceGUID) {
            //handle invalid GUID
        }
        this.loadingStatus = true;
        QRCodeService.fetchButtonStatus(this.deviceGUID!).then(async (data: APIServiceStatus) => {
            this.activeServices = data.Services || [];
            this.loadingStatus = false;
        });
    }

    public requestService(service: ServiceData) {
        if (!this.deviceGUID || !this.deviceData) {
            //handle invalid GUID
        }
        this.loadingServices = {
            ...this.loadingServices,
            [service.ID]: true
        }
        QRCodeService.requestService(service.ButtonValue, this.deviceData!.Device, this.deviceGUID!).then(() => {
            delete this.loadingServices[service.ID];
            this.loadingServices = {
                ...this.loadingServices
            }
        });
    }

    get activeServiceMap() {
        return this.activeServices.reduce((previous, next) => ({...previous, [next.ID]: next}), {});
    }

}
