<template>
    <div class="pad-child" align="center">
        <div v-if="status">
            <div v-if="result">
                <div class="img-wrapper">
                    <i class="dexcon-checkmark"></i>
                </div>
                <h2>{{  getText("reset-success") }}</h2>
                <p>{{  getText("resetinfo") }}: {{ resetInfo.buttonName}} - {{resetInfo.serviceName}}</p>
            </div>
            <div v-else>
                <div class="img-wrapper">
                    <i class="dexcon-close"></i>
                </div>
                <h2>{{  getText("reset-failed") }}</h2>
                <p>{{ error }}</p>
            </div>
            <div class="button-wrapper">
                <a v-if="redir" class="dex-button box outline" href="javascript:history.back()"><i class="dexcon-chevron-left-thin"></i><span>{{ getText("back") }}</span></a>
            </div>
        </div>
        <div v-else>
            <div class="img-wrapper">
                <img src="../../../public/loading.gif" alt="Loading wheel" />
            </div>
            <h2>{{  getText("reset-in-progress") }}</h2>
            <p>{{  getText("please-wait") }}</p>
        </div>
    </div>
</template>

<script>
    import axios from "axios"
    import { mapActions, mapGetters } from "vuex"

    export default {
        name: "ResetPage",
        data() {
            return {
                status: false,
                result: false,
                error: null,
                redir: false,
                resetInfo: {
                    buttonName: null,
                    serviceName: null
                }
            }
        },
        computed: {
            ...mapGetters("lang", {
                getText: "getText"
            })
        },
        methods: {
            ...mapActions("loader", {
                replaceLoader: "replaceLoader"
            }),
            tryReset: function () {
                this.replaceLoader({ loader: true });
                return axios.get(`/api/reset/${this.$route.query.guid}`).then(res => {
                    this.status = true;
                    if (res.data.status) {

                        this.result = true;
                        this.resetInfo.buttonName = res.data.data.buttonName;
                        this.resetInfo.serviceName = res.data.data.serviceName;
                    } else {
                        throw res.data.message[0].message;
                    }
                }).catch(e => {
                    this.error = e;
                }).finally(() => {
                    this.replaceLoader({ loader: false });
                    if (this.$route.query.redir) this.redir = true;
                });
            }
        },
        mounted() {
            this.tryReset();
        }
    }
</script>

<style scoped>
    .pad-child > div > div {
        margin-top: var(--margin-1);
    }

    .img-wrapper {
        height: calc(2.5 * var(--margin-1));
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        display: block;
    }

    .dexcon-close,
    .dexcon-checkmark {
        font-size: var(--xx-large);
        position: relative;
        z-index: 0;
        color: var(--stay-white);
        padding: var(--margin-1);
    }

        .dexcon-close::after,
        .dexcon-checkmark::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
            font-size: var(--xx-large);
            height: calc(2.5 * var(--xx-large));
            width: calc(2.5 * var(--xx-large));
            background: var(--iss-red);
            border-radius: 50%;
        }


        .dexcon-checkmark::after {
            background: var(--free-green);
        }

    .dex-button {
        margin-top: var(--margin-1);
    }
</style>