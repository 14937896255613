export interface SideBarItem {
    icon?: string;
    image?: string;
    label: string;
    href?: string;
}

export interface AvailableLanguage {
    image: string;
    label: string;
    value: string;
    disabled?: boolean;
}

export const AVAILABLE_LANGUAGES: AvailableLanguage[] = [
    {image: '/flags/de.svg', label: 'lang-de', value: 'de'},
    {image: '/flags/uk.svg', label: 'lang-en', value: 'en'},
    {image: '/flags/pt.svg', label: 'lang-pt', value: 'pt'},
    {image: '/flags/dk.svg', label: 'lang-dk', value: 'dk'},
]

export const getLanguageByValue: (value: string) => AvailableLanguage | undefined = (value: string) => {
    return AVAILABLE_LANGUAGES.filter(l => l.value == value)?.[0];
}
export const SIDE_BAR_ITEMS: SideBarItem[] = [
    {
        icon: 'sym_o_home',
        label: 'home',
        href: '/'
    },
    {
        icon: 'sym_o_view_list',
        label: 'services',
        href: '/services'
    },
    {
        icon: 'sym_o_apartment',
        label: 'building_configuration',
        href: '/building-configuration'
    },
    {
        icon: 'sym_o_wysiwyg',
        label: 'settings',
        href: '/settings'
    }];