import {TableService} from "@/services";
import {
    APIColumn,
    apiColumnToTableColumn,
    apiColumnToTableFilterOption, clearFilterValues,
    convertTableFilterToAPIFilters
} from "@/models/api/table.api";

export default {
    namespaced: true,
    state: {
        columns: null,
        filters: null
    },
    getters: {
        getFilters: function (state: any) {
            return state.filters;
        },
        getColumns: function (state: any) {
            return state.columns;
        },
        getSelectedFiltersId(state: any) {
            return convertTableFilterToAPIFilters(state.filters)
        },
    },
    mutations: {
        setFilters: (state: any, payload: any) => {
            state.filters = payload.filters;
        },
        setColumns: (state: any, payload: any) => {
            state.columns = payload.columns;
        }
    },
    actions: {
        getFilters: async (context: any) => {
            if (context.state.filters) {
                return context.state.filters;
            }
            return context.dispatch('getColumns', context);
        },
        getColumns: async (context: any) => {
            if (context.state.columns) {
                return context.state.columns;
            }
            return TableService.fetchColumnsData().then((result: APIColumn[]) => {
                context.commit('setFilters', {filters: result.filter(c => c.Filters).map(apiColumnToTableFilterOption)});
                context.commit('setColumns', {columns: result.map(apiColumnToTableColumn)});
                return context.state.columns;
            });
        },
        clearFilterValues: (context: any) => {
            context.commit('setFilters', {
                filters: clearFilterValues(context.state.filters)
            });
            return context.state.filters;
        }
    }
}