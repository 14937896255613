

import QIcon from 'quasar/src/components/icon/QIcon.js';;
import BouncyRipple from "../BouncyRipple.vue";
import {Component, Prop, Vue} from "vue-property-decorator";
import {APIServiceData} from "@/models/api/device.api";
import TableFilterMenu from "@/components/status-table/TableFilterMenu.vue";

@Component({
    components: {
        TableFilterMenu,
        BouncyRipple,
        QIcon
    },
})
export default class ButtonCard extends Vue {
    @Prop() public service?: APIServiceData;
    @Prop() public showSkeleton?: boolean;
    @Prop() public loading?: boolean;
    @Prop() public disabled?: boolean;
}
