import { render, staticRenderFns } from "./BuildingButtonTemplateDialog.vue?vue&type=template&id=4f0a89fe&scoped=true&"
import script from "./BuildingButtonTemplateDialog.vue?vue&type=script&setup=true&lang=ts&"
export * from "./BuildingButtonTemplateDialog.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f0a89fe",
  null
  
)

export default component.exports
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCardSection,QSeparator,QCardActions,QBtn});
