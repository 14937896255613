
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import QBtn from 'quasar/src/components/btn/QBtn.js';import QList from 'quasar/src/components/item/QList.js';import QMenu from 'quasar/src/components/menu/QMenu.js';import QTree from 'quasar/src/components/tree/QTree.js';;
import {getNumberOfAppliedFilters, TableFilterOption} from "@/models/table.model";
import TableFilterMenuHeader from "@/components/status-table/TableFilterMenuHeader.vue";
import TableFilterOptions from "@/components/status-table/TableFilterOptions.vue";
import {Getter} from "@/store/store.utils";

type Alignment =
    'top left'
    | 'top middle'
    | 'top right'
    | 'top start'
    | 'top end'
    | 'center left'
    | 'center middle'
    | 'center right'
    | 'center start'
    | 'center end'
    | 'bottom left'
    | 'bottom middle'
    | 'bottom right'
    | 'bottom start'
    | 'bottom end';

type Offset = [number, number];
@Component({
    components: {
        TableFilterOptions,
        TableFilterMenuHeader,
        QBtn,
        QList,
        QMenu,
        QTree
    }
})
export default class TableFilterMenu extends Vue {

    @Prop()
    public filterOptions?: TableFilterOption[];
    @Prop()
    anchor?: Alignment;
    @Prop()
    self?: Alignment;
    @Prop()
    offset?: Offset;
    @Prop()
    parent?: TableFilterOption;
    @Prop()
    loading?: boolean;


    @Getter('lang/getText') public translate!: any;

    get menuOpened() {
        return this.$store.getters['ui/getFilterMenuOpened'];
    }

    set menuOpened(value: boolean) {
        this.$store.commit('ui/setFilterMenuOpened', value);
    }

    @Prop()
    disableClearFilter?: boolean;

    @Emit('clearFilters')
    onClearFilters(): void {
    }

    @Emit('onSave')
    onSave(): TableFilterOption[] | undefined {
        return this.filterOptions;
    }

    numberOfAppliedFilters(option: TableFilterOption): number {
        return getNumberOfAppliedFilters([option]);
    }
}
