export default {
    namespaced: true,
    state: {
        filterMenuOpened: false,
        headerBorder: true,
    },
    getters: {
        getFilterMenuOpened(state: any) {
            return state.filterMenuOpened;
        },
        getHeaderBorder(state: any){
            return state.headerBorder;
        }
    },
    mutations: {
        setFilterMenuOpened (state: any, payload: any) {
            state.filterMenuOpened = payload;
        },
        setHeaderBorder (state: any, payload: any) {
            state.headerBorder = payload;
        }
    },
    actions: {
        toggleFilterMenu (context: any) {
            context.commit('setFilterMenuOpened', !context.state.filterMenuOpened);
            return context.state.filterMenuOpened;
        }
    }
}