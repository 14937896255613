import Vue from "vue";

export enum AVAILABLE_EVENTS {
    UPDATE_SETTINGS= 'UPDATE_SETTINGS',
    UPDATE_RECIPIENTS = 'UPDATE_RECIPIENTS',
    UPDATE_SERVICES = 'UPDATE_SERVICES',
    UPDATE_TEMPLATES = 'UPDATE_TEMPLATES',
    UPDATE_BUILDING_CONFIGURATION = 'UPDATE_BUILDING_CONFIGURATION',
    UPDATE_BUILDING_SERVICE_GROUPS= 'UPDATE_BUILDING_SERVICE_GROUPS',
    UPDATE_BUILDING_TEMPLATES= 'UPDATE_BUILDING_TEMPLATES'
}
export const EventBus = new Vue();