
import {Component, Prop, Vue} from "vue-property-decorator";
import TableFilterMenu from "@/components/status-table/TableFilterMenu.vue";
import QBtn from 'quasar/src/components/btn/QBtn.js';import QTree from 'quasar/src/components/tree/QTree.js';;
import {TableFilterOption} from "@/models/table.model";

@Component({
    components: {
        QTree,
        TableFilterMenu,
        QBtn,
    }
})
export default class TableFilterOptions extends Vue {
    @Prop()
    public option?: TableFilterOption;
}
