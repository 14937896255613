import {BASE_URL} from "@/services";
import {APIColumn} from "@/models/api/table.api";
import Store from '@/store';
import axios, {AxiosResponse} from "axios";

export interface PaginationRequest {
    PageNumber: number;
    NumberOfRecordsPerPage: number;
    TotalRecords?: number;
}

export class TableService {
    static async fetchColumnsData(): Promise<APIColumn[]> {
        const response: AxiosResponse<{ Columns: APIColumn[] }> = await axios.get(`${BASE_URL}/v1/status/filters`);
        const filters: APIColumn[] = ((await response).data['Columns'] as APIColumn[]);
        return filters;
    }

    static async fetchTableData(pagination: PaginationRequest): Promise<any> {
        const response: AxiosResponse = await axios.post(`${BASE_URL}/v1/status/data`, {
            Filters: {...Store.getters["filters/getSelectedFiltersId"]},
            Pagination: pagination
        });

        return (await response).data;
    }

    static async resetService(guid: string) {
        const response: AxiosResponse = await axios.get(`${BASE_URL}/reset/${guid}`);

        return (await response).data;
    }
}