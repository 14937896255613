export default ({
    namespaced: true,
    state: {
        loader: 0
    },
    getters: {
        getLoader: function (state) {
            return state.loader > 0 ? true : false;
        }
    },
    mutations: {
        setLoader: function (state, payload) {
            if (payload.loader == undefined) return;
            if (payload.loader) {
                state.loader += 1;
            } else {
                if (state.loader > 0) {
                    state.loader -= 1;
                }
            }
        }
    },
    actions: {
    }
})