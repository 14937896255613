import Vue from 'vue'
import Vuex, {Store} from 'vuex'
import loaderModule from './default/loader.js'
import languageModule from './default/language.js'
import keycloakModule from './default/keycloak'
import filtersModule from './table.store'
import uiModule from './ui.store'
import buildingModule from './building.store';

Vue.use(Vuex)

const store: Store<any> = new Vuex.Store({
    modules: {
        keycloak: keycloakModule,
        lang: languageModule,
        loader: loaderModule,
        filters: filtersModule,
        ui: uiModule,
        building: buildingModule as any
    }
})

export default store;