import { render, staticRenderFns } from "./BuildingConfigurationPage.vue?vue&type=template&id=610c52ac&scoped=true&"
import script from "./BuildingConfigurationPage.vue?vue&type=script&setup=true&lang=ts&"
export * from "./BuildingConfigurationPage.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./BuildingConfigurationPage.vue?vue&type=style&index=0&id=610c52ac&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "610c52ac",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpinnerIos from 'quasar/src/components/spinner/QSpinnerIos.js';
import QField from 'quasar/src/components/field/QField.js';
import QFile from 'quasar/src/components/file/QFile.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QSpinnerIos,QField,QFile});
