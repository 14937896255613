import { render, staticRenderFns } from "./BuildingCardHorizontal.vue?vue&type=template&id=31fd69be&scoped=true&"
import script from "./BuildingCardHorizontal.vue?vue&type=script&setup=true&lang=ts&"
export * from "./BuildingCardHorizontal.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./BuildingCardHorizontal.vue?vue&type=style&index=0&id=31fd69be&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31fd69be",
  null
  
)

export default component.exports
import QChip from 'quasar/src/components/chip/QChip.js';
import QCard from 'quasar/src/components/card/QCard.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QChip,QCard});qInstall(component, 'directives', {Ripple});
