import { render, staticRenderFns } from "./QRPage.vue?vue&type=template&id=bf500204&scoped=true&"
import script from "./QRPage.vue?vue&type=script&lang=ts&"
export * from "./QRPage.vue?vue&type=script&lang=ts&"
import style0 from "./QRPage.vue?vue&type=style&index=0&id=bf500204&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf500204",
  null
  
)

export default component.exports
import QPullToRefresh from 'quasar/src/components/pull-to-refresh/QPullToRefresh.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSpinnerIos from 'quasar/src/components/spinner/QSpinnerIos.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPullToRefresh,QCard,QCardSection,QSkeleton,QIcon,QSpinnerIos});
