import {BASE_URL, partialAPIObject, PartialWithValues, WithRequiredProperties} from "@/services";
import axios, {AxiosResponse} from "axios";
import {APIServiceGroup} from "@/models/api/service-group.api";
import {
    APIButtonTemplateServiceMapping,
    APIService,
} from "@/models/api/service.api";
import {ServiceToCreate} from "@/models/service.model";
import {
    APIBuilding, APIBuildingButtonTemplateData, APIBuildingButtonTemplateFloor, APIBuildingMetadata,
    APIBuildingServiceGroup, APIBuildingServiceGroupRecipient, APIButtonConfigTemplateService,
    CreateBuildingServiceGroup,
    CreateBuildingServiceGroupRecipient, UpdateBuildingServiceGroup
} from "@/models/api/buidling.api";
import {ApiButtonTemplateServiceMapping} from "@/models/api/button-template-service-mapping.api";

export class BuildingService {

    // CREATE
    static async createBuildingServiceGroup(buildingServiceGroupData: CreateBuildingServiceGroup): Promise<any> {
        const createServiceGroupRequestBody = {
            buildingId: buildingServiceGroupData.buildingId,
            serviceGroupId: buildingServiceGroupData.serviceGroupId,
            mailNotificationEnabled: buildingServiceGroupData.enableMailNotification,
            resetEnabeld: buildingServiceGroupData.resetService,
            reminderInterval: buildingServiceGroupData.period,
            minimalMinutesDifference: buildingServiceGroupData.threshold
        };

        const createBuildingServiceGroupRecipient: any = (await axios.post<APIServiceGroup>(`${BASE_URL}/v1/BuildingServiceGroupMappings/Create`, createServiceGroupRequestBody)).data;

        (await this.createBuildingServiceGroupRecipientMapping(
            buildingServiceGroupData.recipientIds.map((rId) => {
                    return {
                        recipientId: rId,
                        buildingServiceGroupMappingId: createBuildingServiceGroupRecipient.id
                    }
                }
            )));

        return createBuildingServiceGroupRecipient;
    }

    static async createBuildingServiceGroupRecipientMapping(mappings: CreateBuildingServiceGroupRecipient[]) {
        return Promise.all(mappings.map(async (mapping: CreateBuildingServiceGroupRecipient): Promise<any> => (await axios.post<APIServiceGroup>(`${BASE_URL}/v1/BuildingServiceGroupMappingRecipientMappings/Create`, mapping)).data));
    }


    // READ
    static async fetchBuildings(withMetadata = true): Promise<APIBuilding[]> {
        const response: AxiosResponse<APIBuilding[]> = await axios.get(`${BASE_URL}/v1/LocationBuildings/Get`);
        const data = (await response).data;

        if (!withMetadata) {
            return data;
        }

        const requests = data.map(async (building, index) => {
            const metadata = (await this.fetchBuildingMetadata([building.id]))[0];
            data[index] = {
                ...data[index],
                metadata
            }
        });

        await Promise.all(requests);

        return data;
    }

    static async fetchBuildingMetadata(buildingIds: number[]): Promise<APIBuildingMetadata[]> {
        const requests: Promise<APIBuildingMetadata>[] = buildingIds.map(async (buildingId, index) => {
            const response: AxiosResponse<APIBuildingMetadata> = await axios.get(`${BASE_URL}/v1/BuildingMetadata/Get/ByBuildingID?buildingID=${buildingId}`);
            const metadata: APIBuildingMetadata = (await response).data;
            return metadata;
        });

        return Promise.all(requests);
    }

    static async fetchBuildingButtonTemplates(buildingId: number): Promise<APIBuildingButtonTemplateFloor[]> {
        const response: AxiosResponse<APIBuildingButtonTemplateFloor[]> = await axios.get(`${BASE_URL}/v1/LocationBuildings/Floors/Buttons?buildingId=${buildingId}`);
        return (await response).data;
    }

    static async fetchBuildingServiceGroups(buildingId: number): Promise<APIBuildingServiceGroup[]> {
        const response: AxiosResponse<APIBuildingServiceGroup[]> = await axios.get(`${BASE_URL}/v1/BuildingServiceGroupMappings/Get/ByBuildingID?buildingID=${buildingId}`);
        return (await response).data;
    }

    static async fetchBuildingServiceGroupRecipients(buildingServiceGroupMappingID: number): Promise<APIBuildingServiceGroupRecipient[]> {
        const response: AxiosResponse<APIBuildingServiceGroupRecipient[]> = await axios.get(`${BASE_URL}/v1/BuildingServiceGroupMappingRecipientMappings/Get/ByBuildingServiceGroupMapping?buildingServiceGroupMappingID=${buildingServiceGroupMappingID}`);
        return (await response).data;
    }


    static async fetchBuildingConfigTemplateServices(buttonConfigTemplateId: number, filterByUnique: boolean = false): Promise<APIButtonConfigTemplateService[]> {
        const response: AxiosResponse<APIButtonConfigTemplateService[]> = await axios.get(`${BASE_URL}/v1/ButtonConfigTemplateServiceMappings/Get/ByButtonConfigTemplate?buttonConfigTemplateID=${buttonConfigTemplateId}`);
        let services: APIButtonConfigTemplateService[] = (await response).data || [];
        if (filterByUnique) {
            services = this.filterServicesInButtonTemplate(services);
        }
        return services;
    }

    private static filterServicesInButtonTemplate(services: APIButtonTemplateServiceMapping[]): APIButtonTemplateServiceMapping[] {
        return services.reduce((p: APIButtonTemplateServiceMapping[], c: APIButtonTemplateServiceMapping) => {
            // if the next object's id is not found in the output array
            // push the object into the output array
            if (!p.some((el: APIButtonTemplateServiceMapping): boolean => {
                return el.serviceId === c.serviceId;
            })) {
                p.push(c)
            }
            return p;
        }, []);
    }

    // UPDATE
    static async updateServiceGroup(serviceGroupId: number, serviceGroupName: string, services: ServiceToCreate[], previousServices: APIService[]): Promise<void> {
        const createServiceGroupRequestBody = {
            id: serviceGroupId,
            name: serviceGroupName,
        };
        const createdServiceGroup: APIServiceGroup = (await axios.put<APIServiceGroup>(`${BASE_URL}/v1/ServiceGroups/Update`, createServiceGroupRequestBody)).data;

        const newServices: APIService[] = previousServices.filter(s => !s.id) ?? [];
        const toUpdateServices: APIService[] = previousServices.filter(s => s.id) ?? [];
        const servicesToDelete: APIService[] = previousServices.filter((previousService) => {
            return (services.filter(s => s.id == previousService.id) || []).length > 0;
        });

        // await Promise.all([createdServices, updatedServices, deletedServices]);

        return;

    }

    static async updateBuildingServiceGroup(buildingServiceGroupData: UpdateBuildingServiceGroup): Promise<any> {
        const createServiceGroupRequestBody = {
            id: buildingServiceGroupData.id,
            buildingId: buildingServiceGroupData.buildingId,
            serviceGroupId: buildingServiceGroupData.serviceGroupId,
            mailNotificationEnabled: buildingServiceGroupData.enableMailNotification,
            resetEnabeld: buildingServiceGroupData.resetService,
            reminderInterval: buildingServiceGroupData.period,
            minimalMinutesDifference: buildingServiceGroupData.threshold
        };

        const updateBuildingServiceGroupRecipient: any = (await axios.put<APIServiceGroup>(`${BASE_URL}/v1/BuildingServiceGroupMappings/Update`, partialAPIObject(createServiceGroupRequestBody))).data;

        (await this.createBuildingServiceGroupRecipientMapping(
            buildingServiceGroupData.recipientIdsToAdd.map((rId) => {
                    return {
                        recipientId: rId,
                        buildingServiceGroupMappingId: updateBuildingServiceGroupRecipient.id
                    }
                }
            ) || []));

        return updateBuildingServiceGroupRecipient;
    }

    static async updateBuildingButtonTemplate(button: WithRequiredProperties<APIBuildingButtonTemplateData, 'id' | 'deviceId'>): Promise<ApiButtonTemplateServiceMapping> {
        return (await axios.put<ApiButtonTemplateServiceMapping>(`${BASE_URL}/v1/Buttons/Update`, partialAPIObject(button))).data;
    }

    static async updateBuildingPhoto(buildingMetadataID: number, buildingID: number, file: File): Promise<ApiButtonTemplateServiceMapping> {
        const formData = new FormData();
        formData.append('ID', buildingMetadataID.toString());
        formData.append('BuildingID', buildingID.toString());
        formData.append('ImageData', file);

        return (await axios.put<ApiButtonTemplateServiceMapping>(`${BASE_URL}/v1/BuildingMetadata/Update`, formData)).data;
    }

    // DELETE
    static async deleteServices(serviceIds: number[]): Promise<APIService[]> {
        const requests: Promise<APIService>[] = serviceIds.map(async (serviceId: number): Promise<APIService> => {
            return (await axios.delete<APIService>(`${BASE_URL}/v1/Services/Delete?id=${serviceId}`)).data;
        });

        return Promise.all(requests);
    }

    static async deleteBuildingServiceGroup(serviceGroupId: number): Promise<void> {
        return (await axios.delete<void>(`${BASE_URL}/v1/BuildingServiceGroupMappings/Delete?id=${serviceGroupId}`)).data;
    }

    static async deleteServiceGroupRecipientMapping(mappingIds: number[]): Promise<APIService[]> {
        const requests: Promise<APIService>[] = mappingIds.map(async (mappingId: number): Promise<APIService> => {
            return (await axios.delete<APIService>(`${BASE_URL}/v1/BuildingServiceGroupMappingRecipientMappings/Delete?id=${mappingId}`)).data;
        });

        return Promise.all(requests);
    }
}