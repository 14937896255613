
import {Vue, Component, Ref} from 'vue-property-decorator';
import QDialog from 'quasar/src/components/dialog/QDialog.js';;
import {Getter} from "@/store/store.utils";

@Component({})
export default class ServiceConfirmation extends Vue {

    public showConfirmDialog: boolean = false;
    public confirmPercentage: number = 0;
    private timoutId?: number;

    private progress = {
        totalTime: 10000,
        interval: 100,
        timeElapsed: 0,
        resolver: () => {
        }
    }

    @Getter('lang/getText') public translate!: any;
    @Ref() readonly dialog!: QDialog;

    // Lifecycle hooks
    mounted() {
    }

    public show() {
        this.dialog.show();
        this.onShowConfirmDialog();
    }

    public hide() {
        this.dialog.hide();
    }

    public onDialogHide() {
        this.$emit('hide');
        clearTimeout(this.timoutId);
    }

    // Script
    public onShowConfirmDialog() {
        this.progress.resolver = () => {
            this.confirmComplete();
        }
        this.updateProgress();

    }

    private updateProgress(): void {
        if (this.progress.timeElapsed >= this.progress.totalTime) {
            this.progress.resolver();
            return;
        }
        this.progress.timeElapsed += this.progress.interval; // Increase time elapsed by 100ms
        const progress = this.progress.timeElapsed / this.progress.totalTime;
        this.confirmPercentage = progress * 100; // Set the value of the progress bar to the percentage of progress

        if (this.progress.timeElapsed < this.progress.totalTime) {
            this.timoutId = setTimeout(this.updateProgress, this.progress.interval); // Call this function again after 100ms
        }
    }

    public confirmComplete() {
        this.$emit('ok');
        this.dialog.hide();
    }
}
