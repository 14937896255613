
import {Vue, Component, Prop, Emit} from 'vue-property-decorator';
import {Getter} from "@/store/store.utils";
import moment from "moment";

@Component({
    computed: {
        moment() {
            return moment
        }
    }
})
export default class TableDataRender extends Vue {
    @Prop({
        required: true
    })
    public row: unknown;

    @Prop({
        required: true
    })
    public column: unknown;

    @Prop()
    public loading?: boolean;

    @Prop()
    public isGrid?: boolean;

    @Getter('lang/getText') public translate!: unknown;

    @Emit('onComplete')
    public onComplete(event: unknown): unknown {
        return event;
    }

}
