import {BASE_URL} from "@/services";
import {APIDeviceData, APIDevice} from "@/models/api/device.api";
import {APIServiceStatus} from "@/models/api/status.api";
import axios, {AxiosResponse} from "axios";

export class QRCodeService {
    static async fetchButtonServices(deviceGuid: string): Promise<APIDeviceData> {
        const response: AxiosResponse<APIDeviceData> = await axios.get(`${BASE_URL}/v1/MultipleActionQRCode/buttonServices?deviceGuid=${deviceGuid}`);

        return (await response).data;
    }



    static async fetchButtonStatus(deviceGuid: string): Promise<APIServiceStatus> {
        const response: AxiosResponse<APIServiceStatus> = await axios.get<APIServiceStatus>(`${BASE_URL}/v1/MultipleActionQRCode/buttonStatus?deviceGuid=${deviceGuid}`);

        return (await response).data;
    }


    static async requestService(buttonValue: number, device: APIDevice, deviceGuid: string): Promise<string> {

        const body = {
            ButtonValue:buttonValue,
            Device:{
                ...device,
                DeviceUID: deviceGuid
            }
        }
        const response: AxiosResponse<string> = await axios.post(`${BASE_URL}/v1/MultipleActionQRCode/requestService`, body);

        return (await response).data;
    }
}