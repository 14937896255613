import {computed, ComputedRef, reactive, Ref, ref} from "vue";
import Dialog from 'quasar/src/plugins/Dialog.js';;
import ProgressDialog from "@/components/dialogs/ProgressDialog.vue";

export type Mode = 'update' | 'read' | 'create';
export type Status = 'info' | 'error' | 'warning' | 'confirm';

export const useDialogStatus = () => {
    const state: {
        status: Status
    } = reactive({
        status: 'info'
    });

    const getStatus: () => Status = () => {
        return state.status;
    }

    const setStatus = (status: Status) => {
        state.status = status;
    }

    return {
        getStatus,
        setStatus
    }
}

export const useDialogMode = () => {
    // const _mode: Ref<Mode> = ref('read');

    const state: { mode: Mode } = reactive({
        mode: 'read'
    });
    const setMode = (mode: Mode): void => {
        state.mode = mode;
    }

    const getMode = (): Mode => {
        return state.mode;
    }

    const isEditable: ComputedRef<boolean> = computed(() => {
        return state.mode == 'create' || state.mode == 'update';
    });

    const isCreating: ComputedRef<boolean> = computed(() => {
        {
            return state.mode == 'create';
        }
    });

    const isUpdating: ComputedRef<boolean> = computed(() => {
        return state.mode == 'update';
    });

    const isRead: ComputedRef<boolean> = computed(() => {
        return state.mode == 'read';
    });

    return {
        setMode: (mode: Mode) => setMode(mode),
        getMode: () => getMode(),
        isEditable,
        isCreating,
        isUpdating,
        isRead
    }
}

export const confirmRemoveDialog = (element: { id?: string | number }, title?: string, description?: string) => {
        if (element?.id) {
            return Dialog.create(
                {
                    component: ProgressDialog,
                    noProgress: true,
                    status: 'error',
                    title: title ?? 'confirm-remove-entry-title',
                    description: description ?? 'confirm-remove-service-description',
                },
            );
        }

        return  undefined;
}