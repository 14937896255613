<template>
    <div @click="onClick" class="relative-position element" :class="{'clicked': clicked, 'disabled': disabled}"
         v-ripple="disabled ? false: { color: 'grey-5',...rippleConf }">
        <slot></slot>
    </div>
</template>

<script>

export default {
    name: "BouncyRipple",
    props: {
        rippleConf: Object,
        disabled: Boolean
    },
    data() {
        return {
            clicked: false,
            animationMilliseconds: 150
        }
    },
    methods: {
        onClick(e) {
            if (this.disabled) {
                return;
            }
            this.$emit('tap', e);
            this.clicked = true;
            setTimeout(() => {
                this.clicked = false;
            }, this.animationMilliseconds)
        }
    }
}
</script>

<style scoped lang="scss">
.element {
  transition: transform 0.15s ease-in-out;
  cursor: pointer;

  &.disabled {
    cursor: auto;
    opacity: .5;
  }

  &.clicked {
    transform: scale(.98);
  }
}
</style>