
import QBtn from 'quasar/src/components/btn/QBtn.js';;
import {Component, Prop, Vue} from "vue-property-decorator";
import {getNumberOfAppliedFilters, TableFilterOption} from "@/models/table.model";
import TableFilterMenu from "@/components/status-table/TableFilterMenu.vue";
import TableFilterOptions from "@/components/status-table/TableFilterOptions.vue";
import {Getter} from "@/store/store.utils";

@Component({
    components: {
        TableFilterOptions,
        TableFilterMenu,
        QBtn,
    }
})
export default class TableFilter extends Vue {
    @Prop()
    public filterOptions?: TableFilterOption[];

    @Prop()
    public loading?: boolean;

    @Getter('lang/getText') public translate!: any;

    get numberOfAppliedFilters(): number {
        return getNumberOfAppliedFilters(this.filterOptions ?? []);
    }
}
