
import {HubConnection, HubConnectionBuilder} from '@aspnet/signalr';
import {EventBus, SOCKET_URL, WS_EVENTS} from "@/services";


export class StatusChange {
    static connection: HubConnection;

    static connect(): void {
        this.connection.start().then(() => {
            this.connection.on(WS_EVENTS.STATUS_CHANGE, action => {
                EventBus.$emit(WS_EVENTS.STATUS_CHANGE, action)
            });

        }, () => {
            setTimeout(() => {
                this.connect()
            }, 5000);
        });

        this.connection.onclose(() => {
            this.connect();
        });
    }

    static start(): void {
        this.connection = new HubConnectionBuilder()
            .withUrl(SOCKET_URL)
            .build();

        setTimeout(() => {
            this.connect();
        }, 1000);
    }
}