import {getSelectedFilters, TableColumn, TableFilterOption, TableId} from "@/models/table.model";

export interface APIFilter {
    ID: string | number;
    Label: string;
    Color?: string;
    Children?: APIFilter[]
}

export interface APIColumn {
    Filters: APIFilter[];
    Key: string;
    Label: string;
}

export function apiColumnToTableFilterOption(column: APIColumn): TableFilterOption {
    return {
        id: column.Key,
        label: column.Label,
        type: hasGrandchildren(column) ? 'tree' : 'checkbox',
        children: convertChildren(column.Filters)
    }
}

export function apiColumnToTableColumn(column: APIColumn): TableColumn {
    return {
        id: column.Key,
        label: column.Label
    }
}


export function convertTableFilterToAPIFilters(tableFilters: TableFilterOption[]): {
    [key: string]: [{ Id: TableId }]
} {
    let result: {} = {};
    tableFilters.forEach((column: TableFilterOption<boolean>) => {
        result = {
            ...result,
            [column.id]:
                getSelectedFilters(column).map(tableId => {
                    return {
                        Id: tableId
                    }
                })
        }
    });

    return result;
}

function convertChildren(filters: APIFilter[]): TableFilterOption[] {
    if (!filters || (filters && filters.length == 0)) {
        return [];
    }
    return filters.map((filter: APIFilter) => {
        return {
            id: filter.ID,
            label: filter.Label,
            children: convertChildren(filter.Children ?? []),
            value: false
        } as TableFilterOption;
    });
}

export function clearFilterValues(filters: TableFilterOption[]): TableFilterOption[] {
    return filters.map((filter: TableFilterOption) => {

        const newValue = 'value' in filter ? {
            value: false
        } : {};
        
        return {
            ...filter,
            ...newValue,
            children: clearFilterValues(filter.children ?? []),
        } as TableFilterOption;
    });
}


function hasGrandchildren(column: APIColumn): boolean {
    if (column.Filters && column.Filters.length > 0) {
        for (const {Children} of column.Filters) {
            if (Children && Children.length > 0) {
                return true;
            }
        }
    }
    return false;
}



