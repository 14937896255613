
import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator'

@Component({})
export default class BlurHash extends Vue {
    @Prop({type: String}) private hash!: string
    @Prop({type: [Number, String], default: 128}) private width!: string | number
    @Prop({type: [Number, String], default: 128}) private height!: string | number
    @Prop({type: Number, default: 1}) private punch!: number
    @Prop({type: String, required: true}) private src!: string
    @Prop({type: String, default: null}) private srcset!: string
    @Prop({type: Number, default: 600}) private transitionDuration!: number;

    public src_: string | null = null;

    @Watch('src', {immediate: true})
    public watchSrc(newValue: string) {
        let url = newValue;
        try {
            window.atob(newValue);
            url = this.handleBase64(newValue);
        } catch (e) {
            return;
        }
        this.previousData = {
            src: this.src_,
            srcset: this.srcset,
            imageLoaded: this.imageLoaded,
            hash: this.hash,
            height: this.height,
            width: this.width,
            punch: this.punch
        };

        this.src_ = url;

        setTimeout(() => {
            this.previousData = null;
        }, this.transitionDuration)
    }

    handleBase64 = (data: string) => {
        return `data:image/png;base64,${data}`;
    }


    public previousData?: {
        src: string | null,
        srcset: string,
        imageLoaded: boolean,
        hash: string;
        height: string | number;
        width: string | number;
        punch: string | number;
    } | null = null;
    imageLoaded = false

    onLoaded() {
        setTimeout(() => {
            this.imageLoaded = true;
        }, 100)
    }

    get cssVars() {
        return {
            '--transition-duration': this.transitionDuration > 0 ? `${this.transitionDuration / 1000}s` : 'none'
        }
    }
}
