import { render, staticRenderFns } from "./NewServiceGroupDialog.vue?vue&type=template&id=4682b974&scoped=true&"
import script from "./NewServiceGroupDialog.vue?vue&type=script&setup=true&lang=ts&"
export * from "./NewServiceGroupDialog.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./NewServiceGroupDialog.vue?vue&type=style&index=0&id=4682b974&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4682b974",
  null
  
)

export default component.exports
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSeparator});
