import { render, staticRenderFns } from "./BuildingButtonCard.vue?vue&type=template&id=941f498e&scoped=true&"
import script from "./BuildingButtonCard.vue?vue&type=script&setup=true&lang=ts&"
export * from "./BuildingButtonCard.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./BuildingButtonCard.vue?vue&type=style&index=0&id=941f498e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "941f498e",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QIcon});
