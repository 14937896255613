export const BASE_URL = process.env.VUE_APP_BASE_URL;
export const SOCKET_URL = process.env.VUE_APP_SOCKET_URL?.startsWith('http') ? process.env.VUE_APP_SOCKET_URL : `${window.location.origin}${process.env.VUE_APP_SOCKET_URL ?? ''}`;


export enum WS_EVENTS {
    STATUS_CHANGE = 'UpdateServiceStatus'
}

export * from './table.service';
export * from './status-change.websocket';
export * from './event-bus';
export * from './qr-code.service';
export * from './building.service';
export * from './settings.service';
export * from './utils'