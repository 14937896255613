<template>
    <div class="pad-child" align="center">
        <div>
            <h2>{{ getText("404") }}</h2>
            <div class="button-wrapper">
                <a class="dex-button box outline" href="javascript:history.back()"><i class="dexcon-chevron-left-thin"></i><span>{{ getText("back") }}</span></a>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"

    export default {
        computed: {
            ...mapGetters("lang", {
                getText: "getText"
            }),
            location: function () {
                return window.location;
            }
        }
    }
</script>