import { render, staticRenderFns } from "./ServiceGroupCard.vue?vue&type=template&id=47dcc604&scoped=true&"
import script from "./ServiceGroupCard.vue?vue&type=script&setup=true&lang=ts&"
export * from "./ServiceGroupCard.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./ServiceGroupCard.vue?vue&type=style&index=0&id=47dcc604&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47dcc604",
  null
  
)

export default component.exports