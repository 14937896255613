<template>
    <div class="pad-child">
        <Splash>
            <div class="splash-content">
                <p class="label">{{ getText("splash-headline-label") }}</p>
                <h1>{{ getText("splash-headline") }}</h1>
                <p>{{ getText("splash-text") }}</p>
                <div class="button-wrapper">
                    <a href="https://www.de.issworld.com/de-de/services/services,-die-wir-anbieten/workplace"
                       target="_blank" class="dex-button box">
                        <span>{{ getText("splash-prompt") }}</span> <i class="dexcon-chevron-right-thin"></i>
                    </a>
                </div>
            </div>
            <img src="splash.jpg" alt="Building Manager adopting Digital Facility Management technology"/>
        </Splash>
    </div>
</template>

<script>
import Splash from "@/components/default/Splash.vue"
import {mapGetters} from "vuex"

export default {
    name: "HomeView",
    components: {
        Splash,
    },
    computed: {
        ...mapGetters("lang", {
            getText: "getText"
        })
    },
    methods: {}
}
</script>


<style scoped lang="scss">
</style>