import { render, staticRenderFns } from "./NewRecipientDialog.vue?vue&type=template&id=0a13faf8&scoped=true&"
import script from "./NewRecipientDialog.vue?vue&type=script&setup=true&lang=ts&"
export * from "./NewRecipientDialog.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a13faf8",
  null
  
)

export default component.exports
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSeparator,QItem,QItemSection,QItemLabel,QToggle});
