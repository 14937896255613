<template>
    <div class="splash-container right">
        <slot></slot>
        <div class="splash-overlay"></div>
    </div>
</template>

<script>
export default {
    name: "ISSSplash"
}
</script>

<style scoped>
.splash-container {
    color: var(--stay-white);
    padding: calc(2 * var(--height-margin-2)) calc(3 * var(--side-margin-15));
    font-size: var(--larger);
    position: absolute;
    height: 100%;
}

.splash-container > .splash-content {
    display: inline-block;
    text-align: center;
    z-index: 2 !important;
    position: relative;
}

.splash-container > .splash-overlay {
    background: var(--iss-blue);
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.splash-container > img {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: top right;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
}

.splash-container > .splash-content .dex-button.box {
    background: none;
    border: 1px solid var(--stay-white);
    color: var(--stay-white);
    font-size: var(--medium);
}

.splash-container > .splash-content .dex-button.box::after {
    background: var(--stay-white);
}

@media only screen and (min-width: 769px) {
    /* Tablet only */
    .splash-container {
        padding: calc(1.5 * var(--height-margin-1)) var(--side-margin-15) !important;
    }

    .splash-container > .splash-content {
        max-width: 60%;
        text-align: left;
    }

    .splash-container > .splash-overlay {
        background: linear-gradient(90deg, var(--iss-blue), rgba(0, 0, 0, 0));
        opacity: 1;
    }

    .splash-container > img {
        object-position: top;
    }
}

@media only screen and (min-width: 1300px) {
    /* Wide desktop only */
    .splash-container {
        margin-left: 0;
        padding: calc(1.5 * var(--height-margin-2)) calc(3.5 * var(--side-margin-1)) !important;
        width: 100%;
        height: 100%;
    }

    .splash-container.right {
    //margin-left: var(--side-margin-15); //width: calc(100% - var(--side-margin-15));
    }
}
</style>